import React from "react";
import VenmoTickets from "../components/VenmoTickets";

const BuyTickets = () => {
  return (
    <div>
      <VenmoTickets />
    </div>
  );
};

export default BuyTickets;
