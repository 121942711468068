import React from "react";
import AdminPlayCreate from "../../admin-components/Plays/admin-play-create";

const AdminPlayAdd = () => {
  return (
    <div>
      <AdminPlayCreate />
    </div>
  );
};

export default AdminPlayAdd;
