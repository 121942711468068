import React from "react";
import AboutMSP from "../components/AboutMSP";

const AboutUs = () => {
  return (
    <div>
      <AboutMSP />
    </div>
  );
};

export default AboutUs;
