import React from "react";
import AdminSponsorCreate from "../../admin-components/Sponsors/admin-sponsor-create";

const AdminSponsorAdd = () => {
  return (
    <div>
      <AdminSponsorCreate />
    </div>
  );
};

export default AdminSponsorAdd;
