import React from "react";
import AdminHub from "../admin-components/admin-hub";

const AdminHome = () => {
  return (
    <div>
      <AdminHub />
    </div>
  );
};

export default AdminHome;
