import React from "react";
import AdminPlayUpdate from "../../admin-components/Plays/admin-play-update";

const AdminPlayEdit = () => {
  return (
    <div>
      <AdminPlayUpdate />
    </div>
  );
};

export default AdminPlayEdit;
