import React from "react";
import AdminSponsorComponent from "../../admin-components/Sponsors/admin-sponsors";

const AdminSponsors = () => {
  return (
    <div>
      <AdminSponsorComponent />
    </div>
  );
};

export default AdminSponsors;
