import React from "react";
import AdminSponsorUpdate from "../../admin-components/Sponsors/admin-sponsor-update";

const AdminSponsorEdit = () => {
  return (
    <div>
      <AdminSponsorUpdate />
    </div>
  );
};

export default AdminSponsorEdit;
