import React from "react";
import AdminPlayComponent from "../../admin-components/Plays/admin-plays";

const AdminPlays = () => {
  return (
    <div>
      <AdminPlayComponent />
    </div>
  );
};

export default AdminPlays;
