import React from "react";
import CurrentlyShowing from "../components/CurrentlyShowing";

const CurrentlyShowingPage = () => {
  return (
    <div>
      <CurrentlyShowing />
    </div>
  );
};

export default CurrentlyShowingPage;
