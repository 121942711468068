import React from "react";
import AdminPlayRemoval from "../../admin-components/Plays/admin-play-removal";

const AdminPlayDelete = () => {
  return (
    <div>
      <AdminPlayRemoval />
    </div>
  );
};

export default AdminPlayDelete;
