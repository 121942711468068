import React from "react";
import AdminSponsorRemoval from "../../admin-components/Sponsors/admin-sponsor-removal";

const AdminSponsorDelete = () => {
  return (
    <div>
      <AdminSponsorRemoval />
    </div>
  );
};

export default AdminSponsorDelete;
