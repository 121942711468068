import React from "react";
import ContactMSP from "../components/ContactMSP";

const ContactUs = () => {
  return (
    <div>
      <ContactMSP />
    </div>
  );
};

export default ContactUs;